import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2811543174/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`A guide for verified card enrollment with the Android SDK`}</h1>
    <p>{`Our SDK is built to also support other use cases (other than Stream). Please take the following steps to integrate and configure the SDK for your Stream application.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: All code examples in this guide and other Android SDK pages will be written in Kotlin, but our SDK works well in Java projects as well.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Note: If an example project helps with your SDK integration & configuration, please check our `}<a parentName="p" {...{
          "href": "https://github.com/Enigmatic-Smile/fidel-android"
        }}>{`GitHub repository`}</a>{`.`}</p>
    </blockquote>
    <h3>{`1. Set up your Fidel API account & your Transaction Stream program`}</h3>
    <p>{`To get started, you'll need a Fidel API account. `}<a parentName="p" {...{
        "href": "https://dashboard.fidel.uk/sign-up"
      }}>{`Sign up`}</a>{`, if you didn't create an account yet.`}</p>
    <p>{`If you didn't create a program for your application yet, please create a Transaction Stream program from your Fidel API dashboard (or via the API).`}</p>
    <h3>{`2. Integrate the Android SDK into your project`}</h3>
    <h4>{`From the remote repository (JitPack)`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Add the JitPack repository to your Android project.`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`If your repositories are declared in your project's `}<inlineCode parentName="p">{`build.gradle`}</inlineCode>{` file, add the JitPack repository:`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-groovy"
              }}>{`fileName:build.gradle
allprojects {
    repositories {
        ...
        maven { url 'https://jitpack.io' }
    }
}
`}</code></pre>
          </li>
          <li parentName="ul">
            <p parentName="li">{`If your project centrally declares repositories, add the JitPack repository in your `}<inlineCode parentName="p">{`settings.gradle`}</inlineCode>{` file:`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-groovy"
              }}>{`fileName:settings.gradle
dependencyResolutionManagement {
    repositoriesMode.set(RepositoriesMode.FAIL_ON_PROJECT_REPOS)
    repositories {
        ...
        maven { url 'https://jitpack.io' }
    }
}
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Check what's the latest version of the Fidel API Android SDK in our Releases page.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Add the latest version of our SDK as a dependency in your app's module `}<inlineCode parentName="p">{`build.gradle`}</inlineCode>{` file:`}</p>
      </li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-groovy"
      }}>{`fileName:app/build.gradle
implementation 'com.github.Enigmatic-Smile:android-sdk:2.2.0'
`}</code></pre>
    <ul>
      <li parentName="ul">{`In the future, to update to the latest Android SDK version, please update the version in your `}<inlineCode parentName="li">{`app/build.gradle`}</inlineCode>{` file and `}<inlineCode parentName="li">{`Sync`}</inlineCode>{` your Android project again.`}</li>
    </ul>
    <h3>{`3. Import the Fidel class`}</h3>
    <blockquote>
      <p parentName="blockquote">{`Important note: The following SDK configuration steps need to be done as soon as the application has finished launching, so in your main Activity’s `}<inlineCode parentName="p">{`onCreate(savedInstanceState: Bundle?)`}</inlineCode>{` function.`}</p>
    </blockquote>
    <p>{`In your main `}<inlineCode parentName="p">{`Activity`}</inlineCode>{` and in other places where you'd want to use the Fidel API Android SDK, please import the SDK's entry point, the `}<inlineCode parentName="p">{`Fidel`}</inlineCode>{` class:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`fileName:MainActivity.kt
import com.fidelapi.Fidel
`}</code></pre>
    <h3>{`4. Set your SDK Key`}</h3>
    <ul>
      <li parentName="ul">{`Please `}<a parentName="li" {...{
          "href": "https://dashboard.fidel.uk/sign-in"
        }}>{`sign into`}</a>{` your Fidel API dashboard account, if you didn't already.`}</li>
      <li parentName="ul">{`Click on your account name `}<em parentName="li">{`(on the top-left hand side of the dashboard)`}</em>{` -> then on `}<inlineCode parentName="li">{`Account Settings`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Go to the `}<inlineCode parentName="li">{`Plan`}</inlineCode>{` tab and copy your `}<inlineCode parentName="li">{`Test`}</inlineCode>{` or `}<inlineCode parentName="li">{`Live`}</inlineCode>{` SDK Key.`}</li>
      <li parentName="ul">{`Set your SDK Key in your app:`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Important note: For security reasons, please DO NOT store the SDK Key in your codebase. Follow our `}<a parentName="p" {...{
          "href": "/stream/sdks/security-guidelines"
        }}>{`SDK security guide`}</a>{` for detailed recommendations.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`Fidel.sdkKey = // set your SDK Key
`}</code></pre>
    <h3>{`5. Set your Program ID`}</h3>
    <ul>
      <li parentName="ul">{`Please `}<a parentName="li" {...{
          "href": "https://dashboard.fidel.uk/sign-in"
        }}>{`sign into`}</a>{` your Fidel API dashboard account, if you didn't already.`}</li>
      <li parentName="ul">{`Go to the `}<inlineCode parentName="li">{`Programs`}</inlineCode>{` section of your Fidel API dashboard.`}</li>
      <li parentName="ul">{`Click on the `}<inlineCode parentName="li">{`Program ID`}</inlineCode>{` of the program that you want to enroll cards into. The program ID will be copied to your pasteboard.`}</li>
      <li parentName="ul">{`Set your Program ID in your app:`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`Fidel.programId = "Your-Program-ID"
`}</code></pre>
    <h3>{`6. Set the program type`}</h3>
    <p>{`For your Stream application you'll need to use a Transaction Stream program, so please set:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`Fidel.programType = ProgramType.TRANSACTION_STREAM
`}</code></pre>
    <h3>{`7. Configure the cardholder consent management feature`}</h3>
    <p>{`Asking for consent from the cardholder to enroll the card in your program is an important part of the SDK. The cardholder will need to read & agree with the conditions expressed using the consent language displayed during the card enrollment process. Making it clear for cardholders is essential.`}</p>
    <h4>{`Set your company name`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`Fidel.companyName = "Your Company Name"
`}</code></pre>
    <h4>{`Set your terms and conditions`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`Fidel.termsAndConditionsUrl = "https://yourwebsite.com/terms"
`}</code></pre>
    <h4>{`Explain how the cardholder can opt out (optional, but recommended)`}</h4>
    <p>{`Please inform the cardholder about how to opt out of transaction monitoring in your program.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`Fidel.deleteInstructions = "how can the cardholder opt out"
`}</code></pre>
    <h4>{`Set your privacy policy (optional, but recommended)`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`Fidel.privacyPolicyUrl = "https://yourwebsite.com/privacy-policy"
`}</code></pre>
    <h3>{`8. Set the allowed country(ies) of card issuance`}</h3>
    <p>{`During card enrollment process, the cardholder needs to select the country where the card was issued. Stream use cases can be activated only for cards issued in the `}<strong parentName="p">{`United States`}</strong>{`, `}<strong parentName="p">{`Canada`}</strong>{` and `}<strong parentName="p">{`United Kingdom`}</strong>{`. Please configure the SDK with the following allowed countries or a subset of these countries, depending on the countries where your application is available. If the subset contains a single country, the cardholders will not need to pick the country. The country that you set will be the default country of issue for the cards that are enrolled in your program.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`Fidel.allowedCountries = setOf(Country.CANADA, Country.UNITED_KINGDOM, Country.UNITED_STATES)
`}</code></pre>
    <h3>{`9. Set Visa as the only supported card scheme`}</h3>
    <p>{`For Stream applications, for now, only Visa card schemes are supported. Please configure the SDK with the following property:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`Fidel.supportedCardSchemes = setOf(CardScheme.VISA)
`}</code></pre>
    <h3>{`10. Communicate your main activity's creation`}</h3>
    <p>{`To handle incomplete card verifications, add the following line in the `}<inlineCode parentName="p">{`onCreate(savedInstanceState: Bundle?)`}</inlineCode>{` function, after you finished all the configuration steps from above.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`Fidel.onMainActivityCreate(this)
`}</code></pre>
    <h1>{`Verified enrollment notifications`}</h1>
    <p>{`In order to be notified about different, useful events (a card was linked, card verification started, card verification failed and others) that happen during a verified enrollment process, we recommend using our `}<a parentName="p" {...{
        "href": "/stream/webhooks"
      }}>{`webhooks`}</a>{`.`}</p>
    <p>{`If client side notifications are useful for your application, make sure to check our SDK callbacks reference documentation.`}</p>
    <h1>{`Enroll and verify a card`}</h1>
    <p>{`Call the `}<inlineCode parentName="p">{`Fidel.start`}</inlineCode>{` function to open the UI and start a verified card enrollment process:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`Fidel.start(context)
`}</code></pre>
    <h3>{`Verified card enrollment flow`}</h3>
    <p>{`The following is a short description of the flow that the cardholders will experience, after calling the `}<inlineCode parentName="p">{`Fidel.start`}</inlineCode>{` method. You can take these steps as well to test the verified card enrollment flow, by setting a test SDK Key and by using the Fidel API `}<a parentName="p" {...{
        "href": "/stream/cards/#test-card-numbers"
      }}>{`test card numbers`}</a>{`.`}</p>
    <p>{`If your Fidel API account is `}<inlineCode parentName="p">{`live`}</inlineCode>{` then cardholders can also enroll real, live cards. Make sure that you set a live SDK Key, in order to allow live verified card enrollments.`}</p>
    <p>{`Description of the flow:`}</p>
    <ol>
      <li parentName="ol">{`An introductory screen is presented informing the cardholder about the verified enrollment flow process.`}</li>
      <li parentName="ol">{`The card enrollment screen is presented, in which cardholders can input their card details and can also provide consent. When successful, the card will be enrolled in your Fidel API program.`}</li>
      <li parentName="ol">{`The SDK will also immediately create a consent object for the card that is enrolled. This is what triggers the start of the verification process.`}</li>
      <li parentName="ol">{`After this step, a card verification screen will be presented. In this screen the cardholder will be able to input the verification token (the micro-charge amount) and complete the verification process.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`Note: If the cardholders using your app do not have access to the card statement (usually, in a corporate setting), preventing them from verifying the card, you can set up the experience to involve a third-party entity (usually, a corporate card administrator) to verify the card.`}</p>
    </blockquote>
    <h3>{`Card verification can be interrupted`}</h3>
    <p>{`After enrolling a card, its verification cannot be guaranteed to happen, for the following reasons:`}</p>
    <ul>
      <li parentName="ul">{`The cardholder voluntarily cancels the process after card enrollment`}</li>
      <li parentName="ul">{`The cardholder closes the app`}</li>
      <li parentName="ul">{`The system closes the app (for example, for memory management reasons, while the cardholder checks the card statement)`}</li>
    </ul>
    <h3>{`How the SDK continues the card verification flow`}</h3>
    <blockquote>
      <p parentName="blockquote">{`Note: To better visualize the process, the following diagram might be useful.`}</p>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "https://mermaid.live/edit#pako:eNqNkctqwzAQRX9l0KqFpB_gRSGuHcgmm3QTLC8GaRwL5FHQIyXE_vcqL5p0Fa2E5pzLXHQSymkSheis-1E9-gjflWTIZ7FYvDVLo8l-HMib7viFXrfvMJ9_QkZu0B0JMbu3aXlaBUCGxBfRkJaSVbZhVUHAA-npapdnfNxSGKFqzvFwFRRG4xiC8kTcPrJrN0Ld1OydtQNxBMPRu2eyviyxvAbSH_oELf_1yEW2LnnA_R5MAE8WE6ue7o3L10s9bFo3axd7wzvoczBxaOEJeqW6mImB_IBG5286nV-kiD0NJEWRr5o6TDZKIXnKKKboNkdWoog-0UykvcZIlcGdx0EUHdpA0y8nGqQF"
      }}><img parentName="a" {...{
          "src": "https://mermaid.ink/img/pako:eNqNkctqwzAQRX9l0KqFpB_gRSGuHcgmm3QTLC8GaRwL5FHQIyXE_vcqL5p0Fa2E5pzLXHQSymkSheis-1E9-gjflWTIZ7FYvDVLo8l-HMib7viFXrfvMJ9_QkZu0B0JMbu3aXlaBUCGxBfRkJaSVbZhVUHAA-npapdnfNxSGKFqzvFwFRRG4xiC8kTcPrJrN0Ld1OydtQNxBMPRu2eyviyxvAbSH_oELf_1yEW2LnnA_R5MAE8WE6ue7o3L10s9bFo3axd7wzvoczBxaOEJeqW6mImB_IBG5286nV-kiD0NJEWRr5o6TDZKIXnKKKboNkdWoog-0UykvcZIlcGdx0EUHdpA0y8nGqQF?type=png",
          "alt": null
        }}></img></a></p>
    <p>{`If the cardholder enrolls a card, but does not complete the verification journey on the spot, your app will have to relaunch the card verification flow when the application becomes active again. The SDK will automatically attempt to complete the card verification process for you. This happens after the following app events:`}</p>
    <ul>
      <li parentName="ul">{`The cardholder attempts to enroll a (new) card (your app calls the `}<inlineCode parentName="li">{`Fidel.start`}</inlineCode>{` method, as indicated above)`}</li>
      <li parentName="ul">{`Your application is relaunched`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Important note: To make sure that the cardholder completes the card verification process, as described above, you must configure the SDK as soon as the app launches, i.e. in the `}<inlineCode parentName="p">{`onCreate(savedInstanceState: Bundle?)`}</inlineCode>{` function of your main `}<inlineCode parentName="p">{`Activity`}</inlineCode>{`.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`How this is done: The SDK knows when to resume card verification, because it stores the card ID (and other useful information) securely on the device. When your application is re-launched, we check if there is an unverified card ID saved. If there is, we'll open the card verification flow.`}</p>
    </blockquote>
    <p>{`You can also manually continue the verification flow for a specific card that was enrolled, but not verified by calling the `}<inlineCode parentName="p">{`Fidel.verifyCard`}</inlineCode>{` method.`}</p>
    <h1>{`Optional: Configure card verification executed by a third-party entity`}</h1>
    <p>{`If it is possible for the cardholders using your application to not have access to their card's statement (in a corporate setting, for example), you can configure the SDK specifically for this use case. The verification process cannot be completed by the cardholder, so a third-party who has access to the card statement needs to be involved. The third-party entity can complete the verification process to enable card data sharing.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: In a setting configured for corporate cards the third party entity is usually a corporate card administrator.`}</p>
    </blockquote>
    <h3>{`Make sure that you followed all mandatory steps to integrate and configure the SDK`}</h3>
    <p>{`Please follow all the steps written above to integrate the SDK and configure it for your application.`}</p>
    <h3>{`Provide cardholders with the option to not verify on the spot`}</h3>
    <p>{`The following property set to `}<inlineCode parentName="p">{`true`}</inlineCode>{` will give the choice to the cardholder to either verify the card on the spot or delegate it to a third-party entity. That depends on wether they have access to the card statement or not.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`Fidel.thirdPartyVerificationChoice = true
`}</code></pre>
    <h3>{`Allow a third-party entity to complete the card verification`}</h3>
    <p>{`If the cardholder has access to the card statement, verification will possibly be completed on the spot. If not, you can call the following function for the third-party entity to complete the card verification process:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`// Create the configuration for card verification
val cardVerificationConfiguration = CardVerificationConfiguration(
    id = "the-card-to-be-verified",
    consentId = "the-consent-id-of-the-card-to-be-verified",
    last4Digits = "7890",
)

// Start card verification from your context, 
// using the configuration you created
Fidel.verifyCard(context, cardVerificationConfiguration)
`}</code></pre>
    <p>{`For more details about this method, please check the Reference docs of the `}<inlineCode parentName="p">{`Fidel.verifyCard`}</inlineCode>{` function and its parameters.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Important note: In order for card verification to be done successfully, make sure that you have set the `}<inlineCode parentName="p">{`sdkKey`}</inlineCode>{` and `}<inlineCode parentName="p">{`programID`}</inlineCode>{` properties correctly, as explained in this guide.`}</p>
    </blockquote>
    <h1>{`Optional: Set any of the other useful properties`}</h1>
    <p>{`Please check our SDK Reference for details about any other SDK properties that might be useful for your application.`}</p>
    <h1>{`Frequently asked questions`}</h1>
    <h3>{`How can I upgrade the Android SDK to the latest version?`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Check what's the latest version of the Fidel API Android SDK in our Releases documentation page.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Add the latest version of our SDK as a dependency in your app's module `}<inlineCode parentName="p">{`build.gradle`}</inlineCode>{` file:`}</p>
      </li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-groovy"
      }}>{`fileName:app/build.gradle
implementation 'com.github.Enigmatic-Smile:android-sdk:{latestFidelSDKVersion}'
`}</code></pre>
    <h3>{`Can I customize the UI of the Android SDK?`}</h3>
    <p>{`The Android SDK offers the `}<inlineCode parentName="p">{`bannerImage`}</inlineCode>{` property for you to set a custom, branded banner image that will be displayed during the card enrollment process. Please check our Reference documentation for more details.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      